import { FuseNavigationItem } from '@fuse/components/navigation';

export const scoutsNavigation: FuseNavigationItem = {
    id   : 'scouts',
    title: 'Scouts',
    type : 'collapsable',
    icon : 'aurora:scouts',
    meta : {
        permission: 'scouts.access',
    },
    children: [
        {
            id   : 'agc',
            title: 'AGC',
            type : 'collapsable',
            icon : 'mat_outline:people',
            meta : {
                permission: 'scouts.contact.access',
            },
            children: [
                {
                    id   : 'contacts',
                    title: 'Contact',
                    type : 'basic',
                    icon : 'mat_outline:contacts',
                    link : '/scouts/contact',
                    meta : {
                        permission: 'scouts.contact.access',
                    },
                },
                {
                    id   : 'organizationalEntities',
                    title: 'OrganizationalEntity',
                    type : 'basic',
                    icon : 'mat_outline:grain',
                    link : '/scouts/organizational-entity',
                    meta : {
                        permission: 'scouts.organizationalEntity.access',
                    },
                },
                {
                    id   : 'jobTitles',
                    title: 'JobTitle',
                    type : 'basic',
                    icon : 'mat_outline:badge',
                    link : '/scouts/job-title',
                    meta : {
                        permission: 'scouts.jobTitle.access',
                    },
                },
                {
                    id   : 'familyUnits',
                    title: 'FamilyUnit',
                    type : 'basic',
                    icon : 'mat_outline:family_restroom',
                    link : '/scouts/family-unit',
                    meta : {
                        permission: 'scouts.familyUnit.access',
                    },
                },
            ],
        },
        {
            id   : 'expenses',
            title: 'Expenses',
            type : 'collapsable',
            icon : 'mat_outline:money_off',
            meta : {
                permission: 'scouts.expense.access',
            },
            children: [
                {
                    id   : 'expenses',
                    title: 'Expense',
                    type : 'basic',
                    icon : 'mat_outline:money_off',
                    link : '/scouts/expense',
                    meta : {
                        permission: 'scouts.expense.access',
                    },
                },
                {
                    id   : 'budgets',
                    title: 'Budget',
                    type : 'basic',
                    icon : 'mat_outline:monetization_on',
                    link : '/scouts/budget',
                    meta : {
                        permission: 'scouts.budget.access',
                    },
                },
                {
                    id   : 'expenseTypes',
                    title: 'ExpenseType',
                    type : 'basic',
                    icon : 'mat_outline:rule_folder',
                    link : '/scouts/expense-type',
                    meta : {
                        permission: 'scouts.expenseType.access',
                    },
                },
            ],
        },
        {
            id   : 'pdj',
            title: 'PDJ',
            type : 'collapsable',
            icon : 'mat_outline:money_off',
            meta : {
                permission: 'scouts.pdj.access',
            },
            children: [
                {
                    id   : 'milestonesTypes',
                    title: 'MilestoneType',
                    type : 'basic',
                    icon : 'mat_outline:rule_folder',
                    link : '/scouts/milestone-type',
                },
                {
                    id   : 'milestonesActions',
                    title: 'MilestoneAction',
                    type : 'basic',
                    icon : 'mat_outline:rule_folder',
                    link : '/scouts/milestone-action',
                },
            ],
        },
        {
            id   : 'presenceControl',
            title: 'PresenceControl',
            type : 'collapsable',
            icon : 'mat_outline:celebration',
            meta : {
                permission: 'scouts.presenceControl.access',
            },
            children: [
                {
                    id   : 'activityTypes',
                    title: 'ActivityType',
                    type : 'basic',
                    icon : 'mat_outline:celebration',
                    link : '/scouts/activity-type',
                },
                {
                    id   : 'activitys',
                    title: 'Activity',
                    type : 'basic',
                    icon : 'mat_outline:celebration',
                    link : '/scouts/activity',
                },
            ],
        },
    ],
};